<template>
  <div class="payee-manage">
    <div class="card mb-3">
      <div class="card-header">
        <h3 class="mb-0"><i class="el-icon-map-location"></i> Payee</h3>
      </div>
      <div class="card-body">
        <div>
          <div class="currency-group" @click="filterByCurrency('')">
            <el-avatar size="small"> All </el-avatar>
          </div>

          <div
            v-for="currency of currencies"
            :key="currency"
            :class="`currency-group ${
              currencySelect == currency ? 'currency-group-active' : ''
            }`"
            @click="filterByCurrency(currency)"
          >
            <el-avatar
              size="small"
              fit="cover"
              :src="`/img/currency-flags/${currency.toLowerCase()}.png`"
            ></el-avatar>
          </div>
          <div class="add-payee">
            <el-dropdown
              v-if="!currencySelect"
              @command="addPayee"
              trigger="click"
              
            >
              <div class="el-dropdown-link">+ Add new payee</div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="currency of currencies"
                  :key="currency"
                  :command="currency"
                >
                  <i class="el-icon-circle-plus"></i>

                  <el-avatar
                    class="dropdown-add-payee-list"
                    size="small"
                    fit="cover"
                    :src="`/img/currency-flags/${currency.toLowerCase()}.png`"
                  ></el-avatar>

                  {{ currency }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>

            <div
              v-if="currencySelect"
              class="el-dropdown"
              @click="addPayee(currencySelect)"
            >
              <div class="el-dropdown-link">+ Add new payee</div>
              
            </div>
          </div>
        </div>
        <div class="mt-2 mb-2">
          <el-input v-model="search" placeholder="Search"></el-input>
        </div>
        <div>
          <data-tables
            :data="
              dataTable.filter(
                (data) =>
                  !search ||
                   data.firstName.toLowerCase().includes(search.toLowerCase()) ||
                data.accountNumber.toLowerCase().includes(search.toLowerCase()) ||
                data.bankName.toLowerCase().includes(search.toLowerCase()) ||
                data.sortcode.toLowerCase().includes(search.toLowerCase()) 
              )
            "
            v-loading="loading"
            border
            size="mini"
            :page-size="10"
            :pagination-props="{
              background: true,
              pageSizes: [10, 20, 50, 100],
            }"
            style="width: 100%"
          >
            <el-table-column v-if="accessRole.payment" label="Send" prop="uid" width="100" align="center">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="success"
                  icon="el-icon-s-promotion"
                  round
                  @click="onSend(scope.row.uid)"
                  >Send</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              label="Currency"
              prop="currency"
              width="100"
              align="center"
            >
              <template slot-scope="scope">
                <el-avatar
                  size="small"
                  fit="cover"
                  :src="`/img/currency-flags/${scope.row.currency.toLowerCase()}.png`"
                ></el-avatar>
                <span style="margin-left: 5px">{{ scope.row.currency }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Name" prop="name">
              <template slot-scope="scope">
                {{ getPayeeName(scope.row) }}
              </template>
            </el-table-column>
            <el-table-column label="Bank" prop="bankName"></el-table-column>
            <el-table-column
              label="Acc. / IBAN"
              prop="accountNumber"
            ></el-table-column>
            <el-table-column
              label="Sortcode / BIC"
              prop="sortcode"
            ></el-table-column>
            <el-table-column label="Action" align="center" width="100">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="warning"
                  @click="onEdit(scope.row)"
                  circle
                  icon="el-icon-edit"
                ></el-button>
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  @click="onDelete(scope.row)"
                ></el-button>
              </template>
            </el-table-column>
          </data-tables>
        </div>
        <payee-gbp-form ref="PayeeGbpForm" v-on:submited="getPayee()" />
        <payee-eur-form ref="PayeeEurForm" v-on:submited="getPayee()" />
        <payee-usd-form ref="PayeeUsdForm" v-on:submited="getPayee()" />
        <payee-thb-form ref="PayeeThbForm" v-on:submited="getPayee()" />
        <payee-form ref="PayeeForm" v-on:submited="getPayee()" />
      </div>
      
    </div>
  </div>
</template>

<script>
import WalletPayeeApi from "@/api/wallet/payee";
import WalletApi from "@/api/wallet/wallet";
import Utils from "@/utils/";
import PayeeGbpForm from "./PayeeGbpForm.vue";
import PayeeEurForm from "./PayeeEurForm.vue";
import PayeeUsdForm from "./PayeeUsdForm.vue";
import PayeeThbForm from "./PayeeThbForm.vue";
import PayeeForm from "./PayeeForm.vue";
export default {
  name: "PayeeManage",
  components: {
    PayeeGbpForm,
    PayeeEurForm,
    PayeeUsdForm,
    PayeeThbForm,
    PayeeForm,
  },
  computed:{
    accessRole() {
      return this.$store.getters.accessrole;
    },
  },
  data() {
    return {
      loading: false,
      payeeLists: [],
      dataTable: [],
      currencies: [],
      currencySelect: "",
      search: "",
    };
  },
  methods: {
    getWallet() {
      this.loading = true;
      WalletApi.getCurrencyActiveList().then(({ result, data }) => {
        this.loading = false;
        if (result) {
          data.forEach(item => { 
            this.currencies.push(item.currency.toUpperCase())
          })
        }
      });
    },
    getPayee() {
      this.loading = true;
      this.payeeLists = [];
      WalletPayeeApi.getPayeeList().then(({ result, data }) => {
        this.loading = false;
        if (result) {
          this.payeeLists = data;
          this.filterByCurrency(this.currencySelect);
          
        }
      });
    },
    getPayeeName(data) {
      return `${data.title} ${data.firstName} ${data.lastName}`;
    },
   
    filterByCurrency(currency = "") {
      this.currencySelect = currency;
      this.dataTable = !currency
        ? this.payeeLists
        : Utils.findObjectArray(this.payeeLists, currency, "currency");
    },
    addPayee(currency) {
      switch (currency) {
        case "GBP":
          this.$refs.PayeeGbpForm.createForm();
          break;
        case "EUR":
          this.$refs.PayeeEurForm.createForm();
          break;
        case "THB":
          this.$refs.PayeeThbForm.createForm();
          break;
        case "USD":
          this.$refs.PayeeUsdForm.createForm();
          break;

        default:
          this.$refs.PayeeForm.createForm(currency);
          break;
      }
    },
    onEdit(data) {
      switch (data.currency) {
        case "GBP":
          this.$refs.PayeeGbpForm.editForm(data.uid);
          break;
        case "EUR":
          this.$refs.PayeeEurForm.editForm(data.uid);
          break;
        case "THB":
          this.$refs.PayeeThbForm.editForm(data.uid);
          break;
        case "USD":
          this.$refs.PayeeUsdForm.editForm(data.uid);
          break;

        default:
          this.$refs.PayeeForm.editForm(data.uid, data.currency);
          break;
      }
    },
    deletePayeeApi(data) {
      WalletPayeeApi.deletePayee(data.uid).then(({ result, message }) => {
        if (result) {
          this.$swal(
            `Message`,
            `Delete Payee <b>${this.getPayeeName(data)}</b> is success`,
            "success"
          ).then(() => {
            
            this.getPayee();
          });
        } else {
          this.$swal("Message", message, "error");
        }
      });
    },
    onDelete(data) {
      this.$swal({
        title: "Delete Payee",
        html: `This will delete payee <b>${this.getPayeeName(
          data
        )}</b>. Continue?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: "Comfirm",
        cancelButtonText: "Cancel",
        showCloseButton: true,
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.deletePayeeApi(data);
        } else {
          this.$message(`Remove payee ${this.getPayeeName(data)} Canceled`);
        }
      });
    },
    setPage(val) {
      this.page = val;
    },
    setPageSize(val) {
      this.pageSize = val;
    },
    onSend(uid) {
      this.$router.push({ path: "/wallet/send", query: { to: uid } });
    },
  },
  mounted() {
    this.getWallet();
    this.getPayee();
  },
};
</script>

<style lang="scss">
.payee-manage {
  .el-avatar {
    display: inline-block;
    vertical-align: middle;
    img {
      display: block;
      height: 100%;
      width: 100%;
    }
  }
  .currency-group {
    display: inline-block;
    vertical-align: middle;
    padding: 5px;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    margin: 10px;
    transition: 0.3s;
    &:hover {
      background-color: #2dce89;
    }
  }
  .add-payee {
    display: inline-block;
    vertical-align: middle;

    cursor: pointer;
    border-radius: 50px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);

    margin: 10px;
    transition: 0.3s;
    padding: 5px;
    &:hover {
      background-color: #2dce89;
    }
    .el-dropdown {
      border-radius: 50px;
      background-color: #1991c0;
      color: #fff;
      .el-dropdown-link {
        padding: 5px;
      }
    }
  }
  .currency-group-active {
    background-color: #2dce89;
  }
}
</style>
