<template>
  <div class="wallet-payee">
    <div class="container-fluid mt--5">
      <payee-manage />
    </div>
  </div>
</template>

<script>
import PayeeManage from "./components/PayeeManage.vue";
export default {
  components: {
    PayeeManage,
  },
};
</script>

<style>
</style>